import React from "react"
import { Card } from "react-bootstrap"

import coursesData from "../assets/data/courses.json"
import { navigate } from "gatsby"
import { Link } from "gatsby"

export default function CourseCards() {
  console.log(coursesData.length)
  if (coursesData.length === 1) {
    console.log("coursesData?", coursesData?.[0].attributes.link)
    if (typeof window !== `undefined`) {
      navigate(`${coursesData?.[0].attributes.link}`)
    }
  }
  return (
    <>
      {coursesData.length !== 1 && (
        <div className="row">
          {coursesData.map(data => {
            return (
              <>
                <div className="col-md-3">
                  <Card className="custom-card">
                    <Card.Img
                      variant="top"
                      src={
                        data?.attributes.image.data?.[0].attributes?.formats
                          ?.thumbnail?.url
                      }
                    />
                    <Card.Body>
                      <div className="row">
                        <div className="col-9">
                          <div className="card-heading fw-bold">
                            {data?.attributes?.course_name}
                          </div>
                        </div>

                        <a target="_blank" href={data?.attributes?.link}>
                          Click to View the Detail of the course
                        </a>
                      </div>
                      <p className="designation-text pt-2">
                        {data?.attributes?.duration}
                      </p>
                    </Card.Body>
                  </Card>
                </div>
              </>
            )
          })}
        </div>
      )}
    </>
  )
}
